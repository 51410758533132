import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { NavLink } from 'react-router-dom';
import { getResponders, postResponder, putResponder, getResponder, deleteResponder } from './RespondersAPI';

import {
  Button,
  Card,
  Grid,
  Icon,
  Table,
  Form,
  Dimmer
} from 'tabler-react';

import { showNotification } from '../shared/Notification';
import Select from 'react-select';
import { showError } from '../shared/FormErrors';
import Timezones from '../shared/Timezones';
import ReactMiniAlertConfirm from '../shared/AlertConfirm';

const conformedPhoneNumber = (phoneNumber) => phoneNumber.replace(/^(\d{3})(\d{3})(\d{4}).*/, '($1) $2-$3');

const formattedTimezone = (timezone) => {
  const index = Timezones.findIndex(v => v.value === timezone);
  return Timezones[index].name;
}

export const ResponderForm = (props) => {

  const id = _.get(props.match.params, "id")

  const defaultResponder = {
    fullName: '',
    email: '',
    phoneNumber: '',
    callsDisabled: false,
    textsDisabled: false,
    emailsDisabled: false,
    tz: '',
  }
  const [errors, setErrors] = useState({});
  const [responder, setResponder] = useState(defaultResponder)
  const [isFormLoading, setIsFormLoading] = useState(false)

  useEffect(() => {
    setIsFormLoading(true);
    if (id) {
      // traer info de API
      getResponder(id)
        .then(res => {
          setResponder(res) // cargo con set Responder
          setIsFormLoading(false);
        })
      // TODO: manejo de errores
    } else {
      setIsFormLoading(false);
    }

  }, [id])

  const isValid = (key) => (_.has(errors, key))

  const getFeedback = (key) => {
    const causes = _.get(errors, key, []);
    return causes.join(", ")
  };

  const preparePayload = (responder) => {
    return {
      ...responder,
      phoneNumber: getResponderId(responder)
    }
  };

  const handleSubmit = (event) => {
    setIsFormLoading(true);
    event.preventDefault();
    if (id) {
      putResponder(preparePayload(responder))
        .then(res => {
          setResponder(defaultResponder);
          setErrors({});
          setIsFormLoading(false);
          showNotification("Success", "Responder has been updated successfully", "success")
          window.history.back("-1"); // TODO: redirect
        })
        .catch((error) => {
          setIsFormLoading(false);
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            setErrors(error.response.data.errors);
            showError(error.response.data.errors);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.error(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            showNotification("Error", error.message, "danger");
          }
          console.log(error.config);
        });
    } else {
      postResponder(preparePayload(responder))
        .then(res => {
          setResponder(defaultResponder);
          setErrors({});
          setIsFormLoading(false);
          showNotification("Success", "Responder has been created successfully", "success")
          window.history.back("-1"); // TODO: redirect
        })
        .catch((error) => {
          setIsFormLoading(false);
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            setErrors(error.response.data.errors);
            showError(error.response.data.errors);
            //console.error(JSON.stringify(error.response.data.errors));
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.error(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            showNotification("Error", error.message, "danger");
          }
          console.log(error.config);
        });
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    setResponder({
      ...responder,
      [name]: value
    });
  };
  
  const handleChangeTimezone = (event) => {
    const value = event.value;

    setResponder({
      ...responder,
      tz: value
    });
  };

  const formTitle = id ? 'Edit Responder' : 'New Responder';

  return (
    <Grid.Row>
      <Grid.Col width={12}>
        <Card>
          <Card.Header>
            <Card.Title>{formTitle}</Card.Title>
          </Card.Header>
          <Card.Body>
            <Dimmer active={ isFormLoading } loader>
              <Form onSubmit={handleSubmit} noValidate>
                <Form.Group>
                  <Form.Label>Phone Number</Form.Label>
                  <Form.MaskedInput
                    name="phoneNumber"
                    type="tel"
                    placeholder="Enter the responder phone number"
                    mask={[
                      "(",
                      /[1-9]/,
                      /\d/,
                      /\d/,
                      ")",
                      " ",
                      /\d/,
                      /\d/,
                      /\d/,
                      "-",
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                    ]}
                    invalid={ isValid("phoneNumber") }
                    feedback={ getFeedback("phoneNumber") }
                    onChange={handleChange}
                    value={conformedPhoneNumber(responder.phoneNumber)}
                  />
                  <div className="form-text small text-muted">
                    We'll never share responder's phone number with anyone else.
                  </div>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Input
                    name="fullName"
                    type="text"
                    placeholder="Enter the responder name"
                    required
                    invalid={ isValid("fullName") }
                    feedback={ getFeedback("fullName") }
                    onChange={handleChange}
                    value={responder.fullName}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Input
                    name="email"
                    type="email"
                    placeholder="Enter the responder email"
                    required
                    invalid={ isValid("email") }
                    feedback={ getFeedback("email") }
                    onChange={handleChange}
                    value={responder.email}
                  />
                </Form.Group>
                <Grid.Row>
                  <Grid.Col width={12} sm={4}>
                    <Form.Group>
                      <Form.Label>
                        <Icon name="phone" /> Calls
                      </Form.Label>
                      <Form.Switch
                        className="inverted-switch"
                        name="callsDisabled"
                        type="checkbox"
                        label=""
                        onChange={handleChange}
                        value={responder.callsDisabled}
                        checked={responder.callsDisabled ? 'checked' : ''}
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col width={12} sm={4}>
                    <Form.Group>
                      <Form.Label>
                        <Icon name="message-circle" /> Texts
                      </Form.Label>
                      <Form.Switch
                        className="inverted-switch"
                        name="textsDisabled"
                        type="checkbox"
                        label=""
                        onChange={handleChange}
                        value={responder.textsDisabled}
                        checked={responder.textsDisabled ? 'checked' : ''}
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col width={12} sm={4}>
                    <Form.Group>
                      <Form.Label>
                        <Icon name="mail" /> Emails
                      </Form.Label>
                      <Form.Switch
                        className="inverted-switch"
                        name="emailsDisabled"
                        type="checkbox"
                        label=""
                        onChange={handleChange}
                        value={responder.emailsDisabled}
                        checked={responder.emailsDisabled ? 'checked' : ''}
                      />
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>
                
                <Form.Group>
                  <Form.Label>Timezone</Form.Label>
                  <Select
                    className="cs-select-container"
                    classNamePrefix="react-select"
                    name="tz"
                    value={Timezones.filter(timezone => timezone.value === responder.tz)}
                    onChange={handleChangeTimezone}
                    isSearchable={true}
                    backspaceRemovesValue={false}
                    options={Timezones}
                    getOptionValue={(Timezones)=>Timezones.value}
                    getOptionLabel={(Timezones)=>Timezones.name}
                    placeholder="Select..."
                    menuPlacement="top"
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>When you flip this switch, you will opt-in for text message alerts. See <a href='https://callsupt.com/#/terms' target='_blank' rel="noopener noreferrer">Terms and Conditions.</a></Form.Label>
                  <Form.Switch
                    className="inverted-switch"
                    name="optInDisabled"
                    type="checkbox"
                    onChange={handleChange}
                    value={responder.optInDisabled}
                    checked={responder.optInDisabled ? 'checked' : ''}
                  />
                </Form.Group>
                
                <Button color="purple" type="submit">Save</Button>
              </Form>
            </Dimmer>
          </Card.Body>
        </Card>
      </Grid.Col>
    </Grid.Row>
  )
}

const getResponderId = (responder) => (responder.phoneNumber && responder.phoneNumber.match(/[0-9]/g).join(''))

const List = () => {
  const [allRows, setAllRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const defaultRemoveData = {
    alert: false,
    id: '',
    name: ''
  }
  const [removeAlert, setRemoveAlert] = useState(defaultRemoveData);

  useEffect(() => {
    setIsLoading(true);
    getResponders().then(data => {
      setAllRows(data);
      setIsLoading(false);
    })
  }, []);

  const handleClickRemoveAlert = (id, name) => {
    setRemoveAlert({alert: true, id: id, name: name});
  }

  const handleDelete = (id) => {
    setIsLoading(true);
    deleteResponder(id)
      .then(res => {
        _.remove(allRows, {
          phoneNumber: id
        });
        setAllRows(allRows);
        showNotification("Success", "Responder has been deleted successfully", "success");
        setRemoveAlert(defaultRemoveData);
        setIsLoading(false);
      })
      .catch((error) => {
        setRemoveAlert(defaultRemoveData);
        setIsLoading(false);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          showNotification("Error", "Responder has not been deleted. Please try again later.", "danger");
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.error(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          showNotification("Error", error.message, "danger");
        }
      });
  }

  const responderItems = (collection) => (
    collection.map(op => ({
      key: op.phoneNumber,
      item: [
        { "data-label": "Phone Number", content: conformedPhoneNumber(op.phoneNumber) },
        { "data-label": "Name", content: op.fullName },
        { "data-label": "Email", content: op.email },
        { "data-label": "Timezone", content: formattedTimezone(op.tz) },
        { "data-label": "Calls", className: "text-lg-center icon-as-data", content: op.callsDisabled ? <Icon name="phone-off" className="text-danger" /> : <Icon name="phone" className="text-success" /> },
        { "data-label": "Texts", className: "text-lg-center icon-as-data", content: op.textsDisabled ? <Icon name="message-circle" className="text-danger" /> : <Icon name="message-circle" className="text-success" /> },
        { "data-label": "Emails", className: "text-lg-center icon-as-data", content: op.emailsDisabled ? <Icon name="mail" className="text-danger" /> : <Icon name="mail" className="text-success" /> },
        { "data-label": "", className: "text-lg-right", content:
          (
            <>
              <NavLink className="btn btn-sm btn-secondary" to={"/responders/" + getResponderId(op) + "/edit"}><Icon name="edit" /></NavLink>
              <Button color="danger" size="sm" className="ml-2" onClick={ () => handleClickRemoveAlert(getResponderId(op), op.fullName) }><Icon name="trash-2" /></Button>
            </>
          ),
        },
      ]
    }))
  );

  return (
    <>
      { removeAlert.alert &&
        <ReactMiniAlertConfirm
          text={"Deleting " + removeAlert.name + ".\nAre you sure?"}
          ico=""
          callback={ () => handleDelete(removeAlert.id) }
          closeCallback={ () => setTimeout(() => { setRemoveAlert(defaultRemoveData); }, 350) }
        />
      }
      <Grid.Row>
        <Grid.Col width={12}>
          <Card>
            <Card.Header>
              <Card.Title>Responders List</Card.Title>
              <Card.Options>
                <NavLink className="btn btn-sm btn-purple" to="/responders/new">
                  <Icon name="plus" />
                </NavLink>
              </Card.Options>
            </Card.Header>
            <Dimmer active={ isLoading } loader>
              <Table
                responsive
                className="card-table table-vcenter responsive-table"
                headerItems={[
                  { content: "Phone Number" },
                  { content: "Name" },
                  { content: "Email" },
                  { content: "Timezone" },
                  { content: "Calls", className: "text-center" },
                  { content: "Texts", className: "text-center" },
                  { content: "Emails", className: "text-center" },
                  { content: "" },
                ]}
                bodyItems={responderItems(allRows)}
              />
            </Dimmer>
          </Card>
        </Grid.Col>
      </Grid.Row>
    </>
  )
}

export default List;
