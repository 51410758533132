import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import AuthorizedLayout from '../shared/AuthorizedLayout';
import { Auth } from "aws-amplify";
import {
  Page,
  Button,
  Card,
  Grid,
  Form,
  Dimmer
} from 'tabler-react';

import { showNotification } from '../shared/Notification';

const Profile = () => {

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const defaultPasswordData = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  }

  const [changePassword, setChangePassword] = useState(defaultPasswordData);

  function validatePassForm() {
    return (
      changePassword.oldPassword.length > 0 &&
      changePassword.newPassword.length > 9 &&
      changePassword.newPassword === changePassword.confirmPassword
    );
  };

  const handlePassChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setChangePassword({
      ...changePassword,
      [name]: value
    });
  };

  async function handlePassSubmit(event) {
    setIsLoading(true);
    event.preventDefault();

    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(
        currentUser,
        changePassword.oldPassword,
        changePassword.newPassword
      );
      setErrors({});
      setIsLoading(false);
      showNotification("Success", "Password has been changed successfully", "success");
      history.push("/app");
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        setErrors(error.response.data.errors);
        console.log(error.response.data.errors);
        showNotification("Error", errors, "danger");
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        showNotification("Error", error.message, "danger");
      }
      console.log(error.config);
    }
  }

  return (
    <AuthorizedLayout>
      <Page.Content title="User Profile">
        <Grid.Row deck>
          <Grid.Col width={12} md={6}>
            <Card>
              <Card.Header>
                <Card.Title>Change your Password</Card.Title>
              </Card.Header>
              <Card.Body>
                <Dimmer active={ isLoading } loader>
                  <Form onSubmit={handlePassSubmit} noValidate>
                    <Form.Group>
                      <Form.Label>Current Password</Form.Label>
                      <Form.Input
                        name="oldPassword"
                        type="password"
                        placeholder="Enter your current password"
                        required
                        onChange={handlePassChange}
                        value={changePassword.oldPassword}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>New Password</Form.Label>
                      <Form.Input
                        name="newPassword"
                        type="password"
                        placeholder="Enter your new password"
                        required
                        onChange={handlePassChange}
                        value={changePassword.newPassword}
                      />
                      <div className="form-text small text-muted">
                        Password requirement: 10 characters min.
                      </div>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Confirm New Password</Form.Label>
                      <Form.Input
                        name="confirmPassword"
                        type="password"
                        placeholder="Confirm your new password"
                        required
                        onChange={handlePassChange}
                        value={changePassword.confirmPassword}
                      />
                    </Form.Group>
                    <Button color="purple" type="submit" disabled={!validatePassForm()}>Save Password</Button>
                  </Form>
                </Dimmer>
              </Card.Body>
            </Card>
          </Grid.Col>
        </Grid.Row>
      </Page.Content>
    </AuthorizedLayout>
  );
};

export default Profile;
