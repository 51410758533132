import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../components/shared/Layout';
import { Container, Row, Col, Navbar, Nav } from 'react-bootstrap';
import axios from 'axios';
import { showNotification } from '../components/shared/Notification';

function scrollToId(element) {
  let anchor = document.querySelector(element);
  const navMenuMobile = document.querySelector(".navbar-collapse");
  let elementPosition = anchor.offsetTop;
  let offsetPosition = elementPosition - 70;
  if (navMenuMobile.classList.contains("show")) {
    document.querySelector(".navbar-toggler").click();
  }
  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth"
  });
};

const onScroll = (e) => {
  if (window.pageYOffset > 48) {
    document.querySelector(".header_area").classList.add("sticky", "slideInDown");
  } else {
    document.querySelector(".header_area").classList.remove("sticky", "slideInDown");
  }
};

function checkRedirectHash(value) {
  if (value.length > 0) {
    scrollToId("#"+value);
  } else {
    window.scrollTo(0, 0); // fix scroll to top when click on footer links
  }
};

export const postEmail = async (payload) => {
  axios.post(process.env.REACT_APP_API_URL + '/emails', payload)
  .then(function (response) {
    console.log(response);
  })
  .catch(function (error) {
    console.log(error);
  });
};

const EmailForm = () => {
  const [fullName, setFullName] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();
  const [isFormLoading, setIsFormLoading] = useState(false);

  const handleSubmit = (event) => {
    setIsFormLoading(true);
    event.preventDefault();
    postEmail({fullName, email, message})
      .then(res => {
        setIsFormLoading(false);
        setFullName("");
        setEmail("");
        setMessage("");
        showNotification("Success", "Email has been sent successfully", "success");
      })
      .catch((error) => {
        showNotification("Error", "Email has not been sent. Try again later.", "danger");
        console.log("error:", error);
      })
  }

  return (
    <form action="#" method="post" id="messageForm" onSubmit={handleSubmit}>
      <div className="contact_input_area">
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="fullName"
                id="name"
                placeholder="Full Name"
                value={fullName || ""}
                required
                onChange={(event) => (setFullName(event.target.value))}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <input
                className="form-control"
                type="email"
                name="email"
                id="email"
                placeholder="E-mail"
                value={email || ""}
                required
                onChange={(event) => (setEmail(event.target.value))}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <textarea
                className="form-control"
                name="message"
                id="message"
                cols="30"
                rows="4"
                placeholder="Message *"
                value={message || ""}
                required
                onChange={(event) => (setMessage(event.target.value))}
              >
              </textarea>
            </div>
          </div>
          <div className="col-12">
            <button type="submit" className="btn submit-btn" disabled={isFormLoading}>Send Now</button>
          </div>
        </div>
      </div>
    </form>
  )
}

const Landing = () => {

  useEffect(() => {
    let redirectHash = window.location.href.split("?")[1] || 0;
    window.addEventListener('scroll', onScroll);
    checkRedirectHash(redirectHash);
    return function cleanup() {
      window.removeEventListener('scroll', onScroll);
    };
  });

  return (
    <Layout>
      <header className="header_area animated">
        <Container fluid>
          <Row className="align-items-center">
            {/* Menu Area*/}
            <Col className="col-12 col-lg-10">
              <div className="menu_area">
                <Navbar expand="lg">
                  <Navbar.Brand href="/">Call Support</Navbar.Brand>
                  <Navbar.Toggle aria-controls="cs-navbar" />
                  <Navbar.Collapse id="cs-navbar">
                    <Nav className="ml-auto" id="nav">
                      <Nav.Link href="#" onClick={() => scrollToId('#home')}>Home</Nav.Link>
                      <Nav.Link href="#" onClick={() => scrollToId('#about')}>About</Nav.Link>
                      <Nav.Link href="#" onClick={() => scrollToId('#features')}>Features</Nav.Link>
                      <Nav.Link className="d-none" href="#" onClick={() => scrollToId('#pricing')}>Pricing</Nav.Link>
                      <Nav.Link href="#" onClick={() => scrollToId('#contact')}>Contact</Nav.Link>
                      <Nav.Link as={Link} className="d-lg-none" to="/login">Login</Nav.Link>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
              </div>
            </Col>
            {/* Signup btn */}
            <div className="col-12 col-lg-2">
              <div className="sign-up-button d-none d-lg-block">
                <Nav.Link as={Link} to="/login">Login</Nav.Link>
              </div>
            </div>
          </Row>
        </Container>
      </header>

      {/* Welcome Area*/}
      <section className="welcome_area clearfix" id="home" style={{backgroundImage: "url(header-bg.png)"}}>
        <Container className="h-100">
          <Row className="h-100 align-items-center">
            <Col className="col-12 col-md">
              <div className="welcome-heading">
                <h1>Collaborative support</h1>
                <h2>CS</h2>
                <p>Keep you team connected with your customer always.</p>
              </div>
              <div className="get-start-area">
                <Nav.Link href="#" onClick={() => scrollToId('#about')}>Get Started</Nav.Link>
              </div>
            </Col>
          </Row>
        </Container>
        {/* Welcome thumb */}
        <div className="welcome-thumb">
          <img src="header-img.png" alt="Call Support to multiple phones" />
        </div>
      </section>

      {/* Special Area*/}
      <section className="special-area bg-white section_padding_100_50" id="about">
        <Container>
          <Row>
            <Col>
              <div className="section-heading text-center">
                <h2>Why CallSupt?</h2>
                <div className="line-shape"></div>
              </div>
            </Col>
          </Row>

          <Row>
            {/* Single Special Area */}
            <Col className="col-12 col-md-4">
              <div className="single-special text-center">
                <div className="single-icon">
                  <i className="ti-mobile" aria-hidden="true"></i>
                </div>
                <h4>Collaborative</h4>
                <p>First responder who pick up the call start the conference call with the customer and the rest of the responders can join the call any time they need to collaborate with the support team.</p>
              </div>
            </Col>
            {/* Single Special Area */}
            <Col className="col-12 col-md-4">
              <div className="single-special text-center">
                <div className="single-icon">
                  <i className="ti-ruler-pencil" aria-hidden="true"></i>
                </div>
                <h4>Easy to use</h4>
                <p>Create the customer on your customer list CallSupt will assign a phone number, give the phone number number to your customer for support calls. Three easy steps to bring to your customer the best support.</p>
              </div>
            </Col>
            {/* Single Special Area */}
            <Col className="col-12 col-md-4">
              <div className="single-special text-center">
                <div className="single-icon">
                  <i className="ti-settings" aria-hidden="true"></i>
                </div>
                <h4>Collect stats</h4>
                <p>Start collection statistics on how many time a customer call support, how many people is involve on this support calls, everything with one click.</p>
              </div>
            </Col>
          </Row>
        </Container>
        {/* Special Description Area */}
        <div className="special_description_area mt-150">
          <Container>
            <Row>
              <Col lg={6} className="mb-5">
                <div className="special_description_img">
                  <img src="about-img.png" alt="Call Support to multiple phones" />
                </div>
              </Col>
              <Col lg={6} xl={5} className="ml-xl-auto">
                <div className="special_description_content">
                  <h2>Why is important customer support?</h2>
                  <p>Increase customer loyalty. Increase how often a customer buys from you. Generate positive word-of-mouth about your business.</p>
                  <p>Customer satisfaction plays an important role, not only is it the leading indicator to measure customer loyalty, identify unhappy customers; it is also a key point of differentiation that helps you to attract new customers in competitive business environments.</p>
                  <p>CallSupt is the tool which allows you give a fast and good customer support in a flexible environment, responders not need to be in a call center not need to have a rigid schedule our tool helps you to always have a responder available to help a customer.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      {/* Awesome Features*/}
      <section className="awesome-feature-area bg-light section_padding_100 clearfix" id="features">
        <Container>
          <Row>
            <Col>
              <div className="section-heading text-center">
                <h2>Features</h2>
                <div className="line-shape"></div>
              </div>
            </Col>
          </Row>

          <Row>
            {/* Single Feature*/}
            <Col className="col-12 col-sm-6 col-lg-4">
              <div className="single-feature">
                <i className="ti-user" aria-hidden="true"></i>
                <h5>Custom Settings</h5>
                <p>Each customer is assigned with his own phone number for support allowing customize each support line for each customer.</p>
              </div>
            </Col>
            {/* Single Feature*/}
            <Col className="col-12 col-sm-6 col-lg-4">
              <div className="single-feature">
                <i className="ti-pulse" aria-hidden="true"></i>
                <h5>List of responders</h5>
                <p>Creates your list of responders and custom settings for each one.</p>
              </div>
            </Col>
            {/* Single Feature*/}
            <div className="col-12 col-sm-6 col-lg-4">
              <div className="single-feature">
                <i className="ti-palette" aria-hidden="true"></i>
                <h5>Statistics</h5>
                <p>Analize your customer support with the data collected from the phone calls.</p>
              </div>
            </div>
          </Row>

        </Container>
      </section>
      {/* Awesome Features End */}

      {/* Video Area*/}
      <div className="video-section d-none">
        <Container>
          <Row>
            <Col>
              <div className="video-area">
                <div className="video-play-btn">
                  <Nav.Link as={Link} to="#videoLink" className="video_btn">
                    <i className="fa fa-play" aria-hidden="true"></i><span className="sr-only">Play Video</span>
                  </Nav.Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Pro Tips Area*/}
      <section className="cool_facts_area clearfix d-none">
        <Container>
          <Row>
            <Col className="col-12 col-md-3 col-lg-3">
              <div className="single-cool-fact d-flex justify-content-center">
                <div className="counter-area">
                  <h3><span className="counter">90</span></h3>
                </div>
                <div className="cool-facts-content">
                  <i className="ion-arrow-down-a"></i>
                  <p>APP <br /> DOWNLOADS</p>
                </div>
              </div>
            </Col>
            <Col className="col-12 col-md-3 col-lg-3">
              <div className="single-cool-fact d-flex justify-content-center">
                <div className="counter-area">
                  <h3><span className="counter">120</span></h3>
                </div>
                <div className="cool-facts-content">
                  <i className="ion-happy-outline"></i>
                  <p>Happy <br /> Clients</p>
                </div>
              </div>
            </Col>
            <Col className="col-12 col-md-3 col-lg-3">
              <div className="single-cool-fact d-flex justify-content-center">
                <div className="counter-area">
                  <h3><span className="counter">40</span></h3>
                </div>
                <div className="cool-facts-content">
                  <i className="ion-person"></i>
                  <p>ACTIVE <br />ACCOUNTS</p>
                </div>
              </div>
            </Col>
            <Col className="col-12 col-md-3 col-lg-3">
              <div className="single-cool-fact d-flex justify-content-center">
                <div className="counter-area">
                  <h3><span className="counter">10</span></h3>
                </div>
                <div className="cool-facts-content">
                  <i className="ion-ios-star-outline"></i>
                  <p>TOTAL <br />APP RATES</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Pricing Plan */}
      <section className="pricing-plane-area section_padding_100_70 clearfix d-none" id="pricing">
        <Container>
          <Row>
            <Col>
              <div className="section-heading text-center">
                <h2>Pricing Plan</h2>
                <div className="line-shape"></div>
              </div>
            </Col>
          </Row>

          <Row className="no-gutters">
            <Col className="col-12 col-md-6 col-lg-3 ml-auto mr-auto">
              <div className="single-price-plan active text-center">
                <div className="package-plan">
                  <h5>Advanced Plan</h5>
                  <div className="cs-price d-flex justify-content-center">
                    <span>$</span>
                    <h4>69</h4>
                  </div>
                </div>
                <div className="package-description">
                  <p>Up to 10 users monthly</p>
                  <p>Unlimited updates</p>
                  <p>Free host &amp; domain</p>
                  <p>24/7 Support</p>
                  <p>10 Unique Users</p>
                </div>
                <div className="plan-button">
                  <Nav.Link href="#" onClick={() => scrollToId('#contact')}>Select Plan</Nav.Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Click to Action Area */}
      <section className="our-monthly-membership section_padding_50 clearfix">
        <Container>
          <Row className="align-items-center">
            <Col md={8}>
              <div className="membership-description">
                <h2>Generate positive word-of-mouth about your business</h2>
                <p>Keep you team connected with your customer always.</p>
              </div>
            </Col>
            <Col md={4}>
              <div className="get-started-button">
                <Nav.Link href="#" onClick={() => scrollToId('#contact')}>Get Started</Nav.Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Other Area*/}
      <section className="our-Team-area bg-white section_padding_100 clearfix" id="other">
        <Container>
          <Row>
            <Col className="text-center col-md-8 offset-md-2">
              <div className="section-heading">
                <h2>Excellent customer service is a competitive advantage</h2>
                <div className="line-shape"></div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-md-8 offset-md-2">
              No matter what industry you're in, you want your business to stand out. After all, nobody strives to be the "second-best" at something. You want to be better than every other company you're competing with and you want your customers to know it, too. That's the key to keeping customers loyal and getting them to continuously interact with your brand.
            </Col>
          </Row>
        </Container>
      </section>

      {/* Contact Us Area*/}
      <section className="footer-contact-area section_padding_100 clearfix" id="contact">
        <Container>
          <Row>
            <Col md={6}>
              {/* Heading Text  */}
              <div className="section-heading">
                <h2>Send email for your beta access</h2>
                <div className="line-shape"></div>
              </div>
              <div className="footer-text">
                <p></p>
              </div>
              <div className="address-text">
                <p><span>Address:</span> NewYork City, US</p>
              </div>
              <div className="email-text">
                <p><span>Email:</span> support@callsupt.com</p>
              </div>
            </Col>
            <Col md={6}>
              <div className="contact_from">
                <EmailForm />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default Landing;
