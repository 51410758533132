import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AuthorizedLayout from '../components/shared/AuthorizedLayout';
import { Page } from 'tabler-react';
import ListCustomers, { CustomerForm } from '../components/customers/List';

const Customers = () => {
  return (
    <AuthorizedLayout>
      <Page.Content title="Customers">
        <Switch>
          <Route path='/customers/:id/edit' component={CustomerForm} />
          <Route path='/customers/new' component={CustomerForm} />
          <Route path='/customers' component={ListCustomers} />
        </Switch>
      </Page.Content>
    </AuthorizedLayout>
  )
}

export default Customers;
