import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../components/shared/Layout';
import { Container, Row, Col, Navbar, Nav } from 'react-bootstrap';

const onScroll = (e) => {
  if (window.pageYOffset > 48) {
    document.querySelector(".header_area").classList.add("sticky", "slideInDown");
  } else {
    document.querySelector(".header_area").classList.remove("sticky", "slideInDown");
  }
};

const Privacy = () => {

  useEffect(() => {
    window.scrollTo(0, 0); // fix scroll to top when click on footer links
    window.addEventListener('scroll', onScroll);
    return function cleanup() {
      window.removeEventListener('scroll', onScroll);
    };
  });

  return (
    <Layout>
      {/* Header Area*/}
      <header className="header_area animated">
        <Container fluid>
          <Row className="align-items-center">
            {/* Menu Area*/}
            <Col className="col-12 col-lg-10">
              <div className="menu_area">
                <Navbar expand="lg">
                  <Navbar.Brand href="/">Call Support</Navbar.Brand>
                  <Navbar.Toggle aria-controls="cs-navbar" />
                  <Navbar.Collapse id="cs-navbar">
                    <Nav className="ml-auto" id="nav">
                      <Nav.Link as={Link} to="/">Home</Nav.Link>
                      <Nav.Link as={Link} to="/?about">About</Nav.Link>
                      <Nav.Link as={Link} to="/?features">Features</Nav.Link>
                      <Nav.Link className="d-none" as={Link} to="/?pricing">Pricing</Nav.Link>
                      <Nav.Link as={Link} to="/?contact">Contact</Nav.Link>
                    </Nav>
                    <div className="sign-up-button d-lg-none">
                      <Nav.Link as={Link} to="/login">Login</Nav.Link>
                    </div>
                  </Navbar.Collapse>
                </Navbar>
              </div>
            </Col>
            {/* Signup btn */}
            <div className="col-12 col-lg-2">
              <div className="sign-up-button d-none d-lg-block">
                <Nav.Link as={Link} to="/login">Login</Nav.Link>
              </div>
            </div>
          </Row>
        </Container>
      </header>

      {/* Welcome Area*/}
      <section className="welcome-section clearfix" style={{backgroundImage: "url(header-bg.png)"}}>
        <Container className="h-100">
          <Row className="h-100 align-items-center">
            <Col className="col-12 col-md">
              <div className="normal-heading">
                <h1>Privacy Policy</h1>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Other Area*/}
      <section className="our-Team-area bg-white section_padding_100 clearfix">
        <Container>
          <Row>
            <Col className="text-center">
              <div className="section-heading">
                <h2>Privacy Policy</h2>
                <div className="line-shape"></div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-md-8 offset-md-2">
              <p>This privacy notice discloses the privacy practices for CallSupt.com, a dba of CallSupt Inc, a Delaware Corp. This privacy notice applies solely to information collected by this website. It will notify you of the following:</p>
              <p>What personally identifiable information is collected from you through the website, how it is used and with whom it may be shared.<br />
              The security procedures in place to protect the misuse of your information.</p>
              <p>Information Collection, Use, and Sharing</p>
              <p>We collect information that you voluntarily provide when you fill out forms or use tools. You may also visit our site anonymously. Some of the information we collect are as follows:</p>
              <p>Contact Information such as name, email address, mailing address, and phone number</p>
              <p>Computer information such as IP address, web browser, referring sites</p>
              <p>We use the information collected in the following manners:</p>
              <p>Research and analysis</p>
              <p>Improve this Web site and personalize marketing efforts<br />
              Personalize your experience, so we can better respond to your needs<br />
              Send newsletters and marketing communications<br />
              Sharing of Information</p>
              <p>We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. We will use your information to respond to you, regarding the reason you contacted us. This does not include trusted third parties such as Secure Rights and their trusted partners who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We only partner with another party to provide specific services. When the user signs up for these services, we will share names or other contact information that is necessary for the third party to provide these services. These parties are not allowed to use personally identifiable information except for the purpose of providing these services. In order to comply with the law, enforce our site policies, or protect ours or others&#8217; rights, property, or safety we may release your information. Non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.</p>
              <p>Google, as a third party vendor, uses cookies to serve ads on your site. Google&#8217;s use of the DART cookie enables it to serve ads to your users based on their visit to your sites and other sites on the Internet. Users may opt out of the use of the DART cookie by visiting the Google ad and content network privacy policy.</p>
              <p>We only have access to/collect information that you voluntarily provide to us via the online forms, email or other forms of direct contact. We are the sole owners of the information collected on this site.</p>
              <p>Unless you ask us not to, we may contact you via email in the future to tell you about specials, new products or services, or changes to this privacy policy.</p>
              <p>Your Access to and Control Over Information</p>
              <p>You may opt out of any future contacts from us at any time by contacting us via the email address or mailing given on our website:</p>
              <p>Have us delete any data we have about you.</p>
              <p>Change/correct any data we have about you.<br />
              Express any concern you have about our use of your data.<br />
              Security</p>
              <p>We take your security seriously. CallSupt.com uses a 128/256 bit industry standard Secure Socket Layer (SSL) encryption for the entire site. We take precautions to protect your information with servers in secured locations and data in private, password protected files. When you submit sensitive information via the website, your information is protected both online and offline.</p>
              <p>Wherever we collect information, that information is encrypted and transmitted to us in a secure way. You can verify this by looking for the https:// at the beginning of the address of the Web page. While we use encryption to protect sensitive information transmitted online, we also protect your information offline. Only employees who need the information to perform a specific job (for example, billing or customer service) are granted access to personally identifiable information. The computers/servers in which we store personally identifiable information are kept in a secure environment.</p>
              <p>No method of transmission over the internet, or method of electronic storage is 100% secure, however. Therefore, we cannot guarantee its absolute security.</p>
              <p>If you feel that we are not abiding by this privacy policy, you should contact us immediately via email at support@CallSupt.com</p>
              <p>No Rights of Third Parties.</p>
              <p>This Privacy Policy does not create rights enforceable by third parties or require disclosure of any personal information relating to users of our websites.</p>
              <p>Registration</p>
              <p>In order to use the services provided by this website, a user must first complete the registration form. During registration, a user is required to give certain information (such as name and email address). This information is used to contact you about the products/services on our site in which you have expressed interest.</p>
              <p>Cookies</p>
              <p>We use &#8220;cookies&#8221; on this site. A cookie is a piece of data stored on a site visitor&#8217;s hard drive to help us improve your access to our site and identify repeat visitors to our site. For instance, when we use a cookie to identify you, you would not have to log in a password more than once, thereby saving time while on our site. Cookies can also enable us to track and target the interests of our users to enhance the experience on our site. Usage of a cookie is in no way linked to any personally identifiable information on our site.</p>
              <p>Some of our business partners such as Google noted above may use cookies on our site (for example, advertisers). However, we have no access to or control over these cookies.</p>
              <p>Yes (Cookies are small files that a site or its service provider transfers to your computers hard drive through your Web browser (if you allow) that enables the sites or service providers systems to recognize your browser and capture and remember certain information.<br />
              Links</p>
              <p>This website contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information.</p>
              <p>California Online Privacy Protection Act Compliance</p>
              <p>Because we value your privacy we have taken the necessary precautions to be in compliance with the California Online Privacy Protection Act. We, therefore, will not distribute your personal information to outside parties without your consent.</p>
              <p>Children’s Online Privacy Protection Act Compliance</p>
              <p>We are in compliance with the requirements of COPPA (Children’s Online Privacy Protection Act), we do not collect any information from anyone under 13 years of age. Our website, products, and services are all directed to people who are at least 13 years old or older.</p>
              <p>CAN-SPAM Compliance</p>
              <p>We have taken the necessary steps to ensure that we are compliant with the CAN-SPAM Act of 2003 by never sending out misleading information.</p>
              <p>Your Consent</p>
              <p>When submitting information via our site, you agree to have your information shared and to be matched with up to four approved lenders and consent (not required as a condition to purchase a good/service) for us or them to contact you via telephone, mobile phone (including through automated dialing, text SMS/MMS, or pre-recorded messaging) and/or email, even if your telephone number is on a corporate, state, or the National Do Not Call Registry, and you agree to our Privacy Policy.</p>
              <p>By using our site, you consent to our privacy policy.</p>
              <p>Changes to our Privacy Policy</p>
              <p>Changes in our privacy policy will be posted on this page, and/or update the Privacy Policy modification date below. Policy changes will apply only to information collected after the date of the change.</p>
              <p>This policy was last modified on 2/09/2020</p>
              <p>Contact Us</p>
              <p>CallSupt.com Inc</p>
              <p>support@callsupt.com</p>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default Privacy;
