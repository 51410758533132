import _ from 'lodash';
import { showNotification } from '../shared/Notification';

const humanFieldNames = {
  "phoneNumber": "Phone Number",
  "email": "Email",
  "fullName": "Name",
  "tz": "Timezone"
}

const errorMessages = {
  "not_present": "The value is not present.",
  "not_valid": "The value is not valid."
}

export const showError = (errors) => {
  // {"phoneNumber":["not_present"],"email":["not_present"],"fullName":["not_present"],"tz":["not_present"]}
  _.forEach(errors, function(type, key) {
    showNotification(`${humanFieldNames[key]}`, `${errorMessages[type]}`, "danger");
  });
};
