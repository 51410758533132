import React, { useContext } from "react";

import { Site } from "tabler-react";
import { AuthenticateContext } from "./AuthorizedLayout"

const SiteHeader = () => {
  const sessionData = useContext(AuthenticateContext);

  return (
    <Site.Header
      href="/#/app"
      alt="Call Support"
      imageURL="logo-callsupport.svg"
      accountDropdown={{
        avatarURL: "logo192.png",
        name: sessionData.user.attributes.email,
        description: '',
        options: [
          { icon: "user", value: "Profile", href: "/#/profile" },
          { icon: "log-out", value: "Logout", href: "/", onClick: () => (sessionData.signOut())  }
        ],
      }}
    />
  );
};

export default SiteHeader;
