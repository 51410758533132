import React from 'react';
import { Link } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-notifications-component/dist/theme.css';
import '../../assets/css/theme.css';
import '../../assets/css/styles.css';

const today = new Date();

const Layout = ({ children }) => (
  <main>
    { children }
    <footer className="footer-social-icon text-center section_padding_70 clearfix">
      <div className="footer-text">
        <h2>Call Support</h2>
      </div>
      <div className="footer-social-icon">
        <Link to="https://facebook.com">
          <i className="fa fa-facebook" aria-hidden="true"></i>
          <span className="sr-only">Facebook</span>
        </Link>
        <Link to="https://twitter.com">
          <i className="fa fa-twitter" aria-hidden="true"></i>
          <span className="sr-only">Twitter</span>
        </Link>
        <Link to="https://instagram.com">
          <i className="fa fa-instagram" aria-hidden="true"></i>
          <span className="sr-only">Instagram</span>
        </Link>
      </div>
      <div className="footer-menu">
        <nav>
          <ul>
            <li><Link to="/terms">Terms &amp; Conditions</Link></li>
            <li><Link to="/privacy">Privacy Policy</Link></li>
          </ul>
        </nav>
      </div>
      <div className="copyright-text">
        <p>Copyright ©{today.getFullYear()}</p>
      </div>
    </footer>
  </main>
);

export default Layout;
