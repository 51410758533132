import React from "react";
import { NavLink as ReactRouterNavLink } from "react-router-dom";

import { Site } from "tabler-react";

const navBarItems = [
  {
    value: "Dashboard",
    to: "/app",
    icon: "home",
    linkAs: ReactRouterNavLink,
    linkProps: { exact: true },
  },
  {
    value: "Customers",
    to: "/customers",
    icon: "briefcase",
    linkAs: ReactRouterNavLink,
  },
  {
    value: "Responders",
    to: "/responders",
    icon: "phone",
    linkAs: ReactRouterNavLink,
  }
];

const SiteNav = function() {
  return (
    <Site.Nav
      isSide={false}
      itemsObjects={navBarItems}
    />
  );
};

export default SiteNav;
