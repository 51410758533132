import React, { useState, useEffect } from 'react';
import { getDashboard } from './DashboardAPI';
import moment from 'moment';
import 'moment-duration-format';
import {
  Page,
  Grid,
  Card,
  Table,
  StampCard,
  Dimmer
} from 'tabler-react';
import Plyr from '../player';

const Dashboard = () => {
  const [allRows, setAllRows] = useState([])
  const [statsConferences, setStatsConferences] = useState([])
  const [statsCalls, setStatsCalls] = useState([])
  const [statsMessages, setStatsMessages] = useState([])
  const [statsCustomers, setStatsCustomers] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true);
    getDashboard().then(data => {
      setAllRows(data.last_conferences);
      setStatsConferences(data.conferences);
      setStatsCalls(data.calls);
      setStatsMessages(data.messages);
      setStatsCustomers(data.customers);
      setIsLoading(false);
    })
  }, []);

  const audioSmallControls = [
    'play',
    'settings',
    'download'
  ];
  const audioSettings = ['speed'];
  const audioSpeed = {
    selected: 0,
    options: [1, 1.5, 2]
  };

  const lastConferencesItems = (collection) => (
    collection.map(lc => ({
      key: lc.date,
      item: [
        { "data-label": "Date", content: moment(lc.date).format("L LTS") },
        { "data-label": "Customer", content: lc.customer },
        { "data-label": "Duration", content: calcTime(lc.duration) },
        { "data-label": "Calls", content: lc.calls },
        { "data-label": "Responders", content: lc.opereratos },
        { "data-label": "Audio", content: lc.recordingUrl && <div className="small-player"><Plyr type="audio" volume={0.75} url={lc.recordingUrl} controls={audioSmallControls} settings={audioSettings} speed={audioSpeed} /></div> }
      ]
    }))
  );

  function calcTime(secs) {
    let timeFormatted = moment.duration(secs, 'seconds').format('h [hr] m [min] s [sec]', {
      trim: "both mid"
    });
    return timeFormatted;
  }

  return (
    <>
      <Page.Content title="Dashboard">
        <p>Current month</p>
        <Grid.Row>
          <Grid.Col className="col-12" sm={6} lg={3}>
            <Dimmer active={ isLoading } loader>
              <StampCard className="tabler-stamp"
                color="blue"
                icon="grid"
                header={
                  <span>
                    { statsConferences.total } <small>Conferences</small>
                  </span>
                }
                footer={ calcTime(statsConferences.total_time) }
              />
            </Dimmer>
          </Grid.Col>
          <Grid.Col className="col-12" sm={6} lg={3}>
            <Dimmer active={ isLoading } loader>
              <StampCard className="tabler-stamp"
                color="green"
                icon="phone-call"
                header={
                  <span>
                    { statsCalls.total } <small>Calls</small>
                  </span>
                }
                footer={ calcTime(statsCalls.total_time) }
              />
            </Dimmer>
          </Grid.Col>
          <Grid.Col className="col-12" sm={6} lg={3}>
            <Dimmer active={ isLoading } loader>
              <StampCard className="tabler-stamp"
                color="red"
                icon="message-square"
                header={
                  <span>
                    { statsMessages.total } <small>Messages</small>
                  </span>
                }
                footer={<span className="invisible">.</span>} />
            </Dimmer>
          </Grid.Col>
          <Grid.Col className="col-12" sm={6} lg={3}>
            <Dimmer active={ isLoading } loader>
              <StampCard className="tabler-stamp"
                color="yellow"
                icon="briefcase"
                header={
                  <span>
                    { statsCustomers.total } <small>Customers</small>
                  </span>
                }
                footer={<span className="invisible">.</span>}
              />
            </Dimmer>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col width={12}>
            <Card title="Last Conferences">
              <Dimmer active={ isLoading } loader>
                <Table
                  className="card-table table-vcenter responsive-table"
                  headerItems={[
                    { content: "Date", className: "w-1" },
                    { content: "Customer" },
                    { content: "Duration" },
                    { content: "Calls" },
                    { content: "Responders" },
                    { content: "Audio" }
                  ]}
                  bodyItems={lastConferencesItems(allRows)}
                />
              </Dimmer>
            </Card>
          </Grid.Col>
        </Grid.Row>
      </Page.Content>
    </>
  )
}

export default Dashboard;
