import API from '../../utils/api';

export const getCustomers = async () => {
  return await API.get('/customers');
};

export const getCustomer = async (id) => {
  return await API.get('/customers/' + id);
};

export const postCustomer = async (data) => {
  return await API.post('/customers', { body: data });
};

export const putCustomer = async (data) => {
  return await API.put('/customers', { body: data });
};

export const deleteCustomer = async (id) => {
  return await API.delete('/customers', { body: { phoneNumber: id } });
};
