import React, { createContext } from "react";
import { ConfirmSignIn, ForgotPassword, RequireNewPassword, SignIn, VerifyContact, withAuthenticator } from 'aws-amplify-react';
import { Auth } from 'aws-amplify';
import { Site } from "tabler-react";
import SiteHeader from "./SiteHeader";
import SiteNav from "./SiteNav";

import '@aws-amplify/ui/dist/style.css';
import 'react-notifications-component/dist/theme.css';

export const AuthenticateContext = createContext();

const LoginHeader = (props) => {
  require('../../assets/css/tabler.css');
  require('../../assets/css/app.css');

  return (
    <div className="tabler app-public">
      <Site>
        <Site.Header
          href="/#/"
          alt="Call Support"
          imageURL="logo-callsupport.svg"
        />
      </Site>
    </div>
  )
}

const LoginFooter = (props) => {
  return (
    <div className="tabler fixed-bottom">
      <Site.Footer copyright={'Copyright ©2020 - Call Support. All rights reserved.'} />
    </div>
  )
}

const authTheme = {
  container :{
    height:"100vh",
    backgroundColor: "#f5f7fb"
  },
  toast: {
    backgroundColor: "#884bdf"
  },
  button: {
    backgroundColor: "#a55eea",
    borderColor: "#a55eea",
    borderRadius: ".25rem"
  }
}

const AuthorizedLayout = ({ children }) => {
  require('../../assets/css/tabler.css');
  require('../../assets/css/plyr.css');
  require('../../assets/css/app.css');

  return(
    <AuthenticateContext.Provider value={Auth}>
      <div className="tabler">
        <Site.Wrapper
          header={SiteHeader}
          nav={SiteNav}
          navIsSide={false}
        >
          {children}

          <Site.Footer copyright={'Copyright ©2020 - Call Support. All rights reserved.'} />
        </Site.Wrapper>
      </div>
    </AuthenticateContext.Provider>
  );
};

export default withAuthenticator(AuthorizedLayout, false, [
  <LoginHeader />,
  <SignIn/>,
  <ConfirmSignIn/>,
  <VerifyContact/>,
  <ForgotPassword/>,
  <RequireNewPassword />,
  <LoginFooter />
], null, authTheme);
