import React from 'react';
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';
import ReactNotification from 'react-notifications-component';
import App from './pages/App';
import Responders from './pages/Responders';
import Customers from './pages/Customers';
import Profile from './components/profile/Profile';
import Landing from './pages/Landing';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import NotFound from './pages/NotFound';

const Router = () => (
  <HashRouter>
    <ReactNotification />
    <Switch>
      <Redirect from='/login' to='/app' />
      <Route path='/app' component={App} />
      <Route path='/responders' component={Responders} />
      <Route path='/customers' component={Customers} />
      <Route path='/profile' component={Profile} />
      <Route exact path='/' component={Landing} />
      <Route exact path='/terms' component={Terms} />
      <Route exact path='/privacy' component={Privacy} />
      <Route component={NotFound} />
    </Switch>
  </HashRouter>
);

export default Router;
