import React from 'react';
import AuthorizedLayout from '../components/shared/AuthorizedLayout';
import Dashboard from '../components/dashboard/Dashboard';

const App = () => {
  return (
    <AuthorizedLayout>
      <Dashboard />
    </AuthorizedLayout>
  )
}

export default App;
