import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../components/shared/Layout';
import { Container, Row, Col, Navbar, Nav } from 'react-bootstrap';

const onScroll = (e) => {
  if (window.pageYOffset > 48) {
    document.querySelector(".header_area").classList.add("sticky", "slideInDown");
  } else {
    document.querySelector(".header_area").classList.remove("sticky", "slideInDown");
  }
};

const Terms = () => {

  useEffect(() => {
    window.scrollTo(0, 0); // fix scroll to top when click on footer links
    window.addEventListener('scroll', onScroll);
    return function cleanup() {
      window.removeEventListener('scroll', onScroll);
    };
  });

  return (
    <Layout>
      {/* Header Area*/}
      <header className="header_area animated">
        <Container fluid>
          <Row className="align-items-center">
            {/* Menu Area*/}
            <Col className="col-12 col-lg-10">
              <div className="menu_area">
                <Navbar expand="lg">
                  <Navbar.Brand href="/">Call Support</Navbar.Brand>
                  <Navbar.Toggle aria-controls="cs-navbar" />
                  <Navbar.Collapse id="cs-navbar">
                    <Nav className="ml-auto" id="nav">
                      <Nav.Link as={Link} to="/">Home</Nav.Link>
                      <Nav.Link as={Link} to="/?about">About</Nav.Link>
                      <Nav.Link as={Link} to="/?features">Features</Nav.Link>
                      <Nav.Link className="d-none" as={Link} to="/?pricing">Pricing</Nav.Link>
                      <Nav.Link as={Link} to="/?contact">Contact</Nav.Link>
                    </Nav>
                    <div className="sign-up-button d-lg-none">
                      <Nav.Link as={Link} to="/login">Login</Nav.Link>
                    </div>
                  </Navbar.Collapse>
                </Navbar>
              </div>
            </Col>
            {/* Signup btn */}
            <div className="col-12 col-lg-2">
              <div className="sign-up-button d-none d-lg-block">
                <Nav.Link as={Link} to="/login">Login</Nav.Link>
              </div>
            </div>
          </Row>
        </Container>
      </header>

      {/* Welcome Area*/}
      <section className="welcome-section clearfix" style={{backgroundImage: "url(header-bg.png)"}}>
        <Container className="h-100">
          <Row className="h-100 align-items-center">
            <Col className="col-12 col-md">
              <div className="normal-heading">
                <h1>Terms &amp; Conditions</h1>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Other Area*/}
      <section className="our-Team-area bg-white section_padding_100 clearfix">
        <Container>
          <Row>
            <Col className="text-center">
              <div className="section-heading">
                <h2>Terms of Service</h2>
                <div className="line-shape"></div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-md-8 offset-md-2">
              <p>Acceptance Of Terms<br />
              Updated 8/01/2023</p>
              <p>The website callsupt.com and all pages and services contained herein, are owned and operated by callsupt.com, dba CallSupt Inc, a DE corp and is accessed by you under the Terms of Use described below (“Terms”).</p>
              <p>Please read these terms carefully before using services found at callsupt.com. By accessing the site, viewing any content or using any services available you are agreeing to be bound by the terms set forth here, which with our Privacy policy, governs our relationship with you in relation to the site. If you disagree with any part of the terms then you may not access the site.</p>
              <p>Description Of Service<br />
              callsupt.com is a cloud-based software as a service providing one or more of the following services: a responsive web-based interface, local and toll-free numbers, call and text data storage as well as software maintenance and upgrades and customer support.</p>
              <p>Use of Services<br />
              Customer will be solely responsible for all use (whether or not authorized) of the callsupt.com Services under its account. Customer will ensure that it has a written agreement with each End User (&#8220;End User Agreement&#8221;) that enables callsupt.com to use all Customer Data as necessary to provide the Services and that protects callsupt.coms’ rights to the same extent as the terms of this Agreement.</p>
              <p>The End User Agreement must include terms concerning restrictions on use, protection of proprietary rights, disclaimer of warranties and limitations of liability. callsupt.com agrees that, subject to the foregoing requirements, the End User Agreement need not specifically refer to callsupt.coms</p>
              <p>Services described on the callsupt.com sites may or may not be available in all countries or regions of the world and may be available under different trademarks in different countries. Customer agrees to use the callsupt.com Services in accordance with all applicable guidelines as well as all state and federal laws that the Services are subject to.</p>
              <p>User Accounts<br />
              Customer may use the service through the use of their username and password login. Customer shall provide callsupt.com with accurate, complete and regularly updated profile information associated with their login. Customer shall be responsible for maintaining the confidentiality of their password. Customer agrees to take all reasonable precautions to prevent unauthorized access to or use of the callsupt.com Services and notify callsupt.com promptly of any such unauthorized access or use. Customer agrees to notify callsupt.com of any known or suspected unauthorized use of their user login account, including suspected or known breach of security, including loss, theft, or unauthorized disclosure of your username or password.</p>
              <p>Customer will promptly notify callsupt.com if it becomes aware of any breach of the terms of the End User Agreement that may affect callsupt.com.</p>
              <p>Any fraudulent, abusive, or otherwise illegal activity may be grounds for termination of the user account, in callsupt.coms’ sole discretion and Customer may be reported to appropriate law-enforcement agencies.</p>
              <p>Phone Numbers<br />
              callsupt.com will use commercially reasonable efforts to obtain the telephone number(s) requested, and use commercially reasonable efforts to initiate and complete port requests from other providers to callsupt.com</p>
              <p>Customer acknowledges that if the Service is suspended or terminated due to non-payment or breach of this Agreement, Customer may not be able to obtain the same local or toll-free numbers should the Service be reactivated. callsupt.com may, after 30 days post-cancellation of service and the inability or lack of desire to port the telephone number, remove all telephone numbers from Account.</p>
              <p>Phone Number Portability<br />
              Phone numbers acquired through callsupt.com can be ported to another carrier after at least 90 days of use from the time of initial purchase. Any attempts to use callsupt.com merely a source of numbers to be immediately ported away without use of the numbers on our system is not permitted and is grounds for Termination.</p>
              <p>Customer Compliance with Laws<br />
              Customer assumes all knowledge of applicable law and is responsible for compliance with any such laws.</p>
              <p>Customers agree to take full responsibility to familiarize himself/herself with and abide by all applicable local, state, national and international laws, and regulations and are solely responsible for all acts or omissions that occur under their account, including without limitation the content of the phone calls and messages transmitted through the callsupt.com Services. Customer may not use the Service in any way that violates applicable state, federal, or international laws, regulations or other government requirements. Customers using the Service for telemarketing or similarly regulated purposes are directly and solely responsible for compliance with all applicable laws, including without limitation for filing, subscribing, or holding appropriate regulatory compliance documentation as required by competent jurisdictions and/or the purchase of access to any do-not-call lists and the like.</p>
              <p>Customer accepts that the Services are provided for professional use only, and agree that their use of the CallSupt Sites or Services shall not include:</p>
              <p>Sending unsolicited marketing messages or broadcasts (i.e. spam);<br />
              Customer further agrees not to transmit any material that encourages conduct that could constitute a criminal offense, give rise to civil liability or otherwise violate any applicable local, state, national, or international law or regulation.</p>
              <p>Engaging in any other activity that callsupt.com believes could subject it to criminal liability or civil penalty/judgment.<br />
              Using strings of numbers as it is unlawful to engage two or more lines of a multi-line business;<br />
              Harvesting, or otherwise collecting information about others, without their consent;<br />
              Attempting to gain unauthorized access to the Services, other accounts, computer systems or networks connected to the Services, through password mining or any other means;<br />
              Interfering with another&#8217;s use and enjoyment of the Services or CallSupt Sites.</p>
              <p>Calls may be monitored and reported upon each billing cycle to ensure sound traffic patterns. Any violation of callsupt.com policies will be grounds for termination of the Account, at callsupt.coms&#8217; sole discretion, Customer may be reported to appropriate law enforcement agencies.</p>
              <p>Disclaimer Regarding Accuracy of Vendor Information<br />
              Product specifications and other information have either been provided by the Vendors or collected from publicly available sources. callsupt.com makes every effort to ensure information on this website is accurate, we can make no representations or warranties as to the accuracy or reliability of any information provided on this website.</p>
              <p>callsupt.com makes no warranties or representations whatsoever with regard to any product, data or service provided or offered by any Vendor, and you acknowledge that any reliance on representations and warranties provided by any Vendor shall be at your own risk.</p>
              <p>Transactional Text Messages</p>
              <p>By accepting these Terms and Conditions, you acknowledge and agree that CallsUpt.com may send text messages containing transactional messages to your registered phone number. These transactional messages are sent in compliance with the A2P 10DLC registration requirements for US messaging. Transactional messages may include but are not limited to account updates, notifications, alerts, and other essential communications related to your use of CallsUpt.com services.</p>
              <p>Disclaimer<br />
              callsupt.com, dba CallSupt INC HEREBY DISCLAIMS ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, TITLE, NON-INFRINGEMENT, AND FITNESS FOR A PARTICULAR PURPOSE AND WARRANTIES RELATED TO THIRD PARTY EQUIPMENT, MATERIAL, SERVICES OR SOFTWARE. callsupt.com SERVICES AND PROPERTIES ARE PROVIDED &#8220;AS IS&#8221; TO THE FULLEST EXTENT PERMITTED BY LAW. TO THE EXTENT SUCH DISCLAIMER CONFLICTS WITH APPLICABLE LAW, THE SCOPE AND DURATION OF ANY APPLICABLE WARRANTY WILL BE THE MINIMUM PERMITTED UNDER SUCH LAW. callsupt.com MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THIS SITE, THE CALL TRACKING SERVICE OR THE INFORMATION, CONTENT, MATERIALS, OR PRODUCTS INCLUDED OR GATHERED ON THIS SITE. YOU EXPRESSLY AGREE THAT YOUR USE OF THIS SITE AND SERVICE IS AT YOUR SOLE RISK.TO THE EXTENT SUCH DISCLAIMER CONFLICTS WITH APPLICABLE LAW, THE SCOPE AND DURATION OF ANY APPLICABLE WARRANTY WILL BE THE MINIMUM PERMITTED UNDER SUCH LAW.</p>
              <p>Release And Indemnity<br />
              You hereby expressly and irrevocably release and forever discharge callsupt.com , its affiliated and associated companies, and their respective directors, officers, employees, agents, representatives, independent and dependent contractors, licensees, successors and assigns of and from any and all actions, causes of action, suits, proceedings, liability, debts, judgments, claims and demands whatsoever in law or equity which you ever had, now have, or hereafter can, shall or may have, for or by reason of, or arising directly or indirectly out of your use of the Site and the Services. You hereby agree to indemnify and hold harmless callsupt.com, its affiliated and associated companies, and their respective directors, officers, employees, agents, representatives, independent and dependent contractors, licensees, successors and assigns from and against all claims, losses, expenses, damages and costs (including, but not limited to, direct, incidental, consequential, exemplary and indirect damages), and reasonable attorneys&#8217; fees, resulting from or arising out of (a) a breach of these Terms, (b) the use of the Services, by you or any person using our services, or (c) any violation of any rights of a third party.</p>
              <p>Indemnification by Customer<br />
              Customer will defend, indemnify and hold callsupt.com harmless against any actual or threatened claim, loss, liability, proceeding, third-party discovery demand, governmental investigation or enforcement action arising out of or relating to Customer&#8217;s activities under this Agreement or Customer&#8217;s acts or omissions in connection with the provision of these terms. callsupt.com will cooperate as fully as reasonably required in the defense of any Claim, at Customer&#8217;s expense. callsupt.com reserves the right, at Customer&#8217;s expense, to retain separate counsel for callsupt.com or, if Customer has not responded reasonably to the applicable Claim, to assume the exclusive defense and control of any matter in which Customer is a named party and that is otherwise subject to indemnification under this Section. Customer will pay all costs, reasonable attorneys&#8217; fees and any settlement amounts or damages awarded against Twilio in connection with any Claim. Customer will also be liable to Twilio for any costs and attorneys&#8217; fees Twilio incurs to successfully establish or enforce its right to indemnification under this Section.</p>
              <p>Trademarks<br />
              All contents of the Websites are: Copyright © 2023 callsupt.com dba CallSupt Inc. All other product and company names mentioned herein, including the names of Lenders, may be the trademarks of their respective owners.</p>
              <p>Links to Third Party Websites<br />
              We may link to websites maintained by third parties. Such links are provided for your convenience and reference only. callsupt.com does not operate or control in any respect any information, software, products or services available on such websites. callsupt.com&#8217;s inclusion of a link to a website does not imply any endorsement of the services or the website, its contents. callsupt.com is not responsible for the accuracy or content of the information provided by that website, nor is it liable for any direct or indirect technical or system issues arising out of your access to or use of third-party technologies or programs available through that website.</p>
              <p>Questions<br />
              Feel free to contact us with any questions via email at support@callsupt.com</p>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default Terms;
