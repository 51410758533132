import API from '../../utils/api';

export const getResponders = async () => {
  return await API.get('/responders');
};

export const getResponder = async (id) => {
  return await API.get('/responders/' + id);
};

export const postResponder = async (data) => {
  return await API.post('/responders', { body: data });
};

export const putResponder = async (data) => {
  return await API.put('/responders', { body: data });
};

export const deleteResponder = async (id) => {
  return await API.delete('/responders', { body: { phoneNumber: id } });
};
