import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AuthorizedLayout from '../components/shared/AuthorizedLayout';
import { Page } from 'tabler-react';
import ListResponders, { ResponderForm } from '../components/responders/List';

const Responders = () => {
  return (
    <AuthorizedLayout>
      <Page.Content title="Responders">
        <Switch>
          <Route path='/responders/:id/edit' component={ResponderForm} />
          <Route path='/responders/new' component={ResponderForm} />
          <Route path='/responders' component={ListResponders} />
        </Switch>
      </Page.Content>
    </AuthorizedLayout>
  )
}

export default Responders;
