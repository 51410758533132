import React from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const NotFound = () => (
  <main>
    <Row>
      <Col>Page not found!</Col>
    </Row>
  </main>
);

export default NotFound;
