import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { NavLink } from 'react-router-dom';
import { getCustomers, postCustomer, putCustomer, getCustomer, deleteCustomer } from './CustomersAPI';

import {
  Button,
  Card,
  Grid,
  Icon,
  Table,
  Form,
  Dimmer
} from 'tabler-react';

import { showNotification } from '../shared/Notification';
import Select from 'react-select';
import { showError } from '../shared/FormErrors';
import Timezones from '../shared/Timezones';
import ReactMiniAlertConfirm from '../shared/AlertConfirm';
import { CSVLink } from 'react-csv';

const conformedPhoneNumber = (phoneNumber) => phoneNumber.slice(2).replace(/^(\d{3})(\d{3})(\d{4}).*/, '($1) $2-$3');

const formattedTimezone = (timezone) => {
  const index = Timezones.findIndex(v => v.value === timezone);
  return Timezones[index].name;
}

export const CustomerForm = (props) => {

  const id = _.get(props.match.params, "id")

  const defaultCustomer = {
    phoneNumber: '',
    fullName: '',
    email: '',
    disabled: false,
    tz: '',
    callRecording: false,
    supportMenuDisabled: true,
    accessCodeDisabled: true,
    accessCode: '',
  }
  const [errors, setErrors] = useState({});
  const [customer, setCustomer] = useState(defaultCustomer)
  const [isFormLoading, setIsFormLoading] = useState(false)

  useEffect(() => {
    setIsFormLoading(true);
    if (id) {
      // traer info de API
      getCustomer(id.slice(1))
        .then(res => {
          setCustomer(res)
          setIsFormLoading(false);
        })
      // TODO: manejo de errores
    } else {
      setIsFormLoading(false);
    }

  }, [id])

  const isValid = (key) => (_.has(errors, key))

  const getFeedback = (key) => {
    const causes = _.get(errors, key, []);
    return causes.join(", ")
  };

  const preparePayload = (customer) => {
    return {
      ...customer,
      phoneNumber: getCustomerId(customer)
    }
  };

  const handleSubmit = (event) => {
    setIsFormLoading(true);
    event.preventDefault();
    if (id) {
      putCustomer(preparePayload(customer))
        .then(res => {
          setCustomer(defaultCustomer);
          setErrors({});
          setIsFormLoading(false);
          showNotification("Success", "Customer has been updated successfully", "success")
          window.history.back("-1"); // TODO: redirect
        })
        .catch((error) => {
          setIsFormLoading(false);
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            setErrors(error.response.data.errors);
            showError(error.response.data.errors);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.error(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            showNotification("Error", error.message, "danger");
          }
          console.log(error.config);
        });
    } else {
      postCustomer(preparePayload(customer))
        .then(res => {
          setCustomer(defaultCustomer);
          setErrors({});
          setIsFormLoading(false);
          showNotification("Success", "Customer has been created successfully", "success")
          window.history.back("-1"); // TODO: redirect
        })
        .catch((error) => {
          setIsFormLoading(false);
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            setErrors(error.response.data.errors);
            showError(error.response.data.errors);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.error(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            showNotification("Error", error.message, "danger");
          }
          console.log(error.config);
        });
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    setCustomer({
      ...customer,
      [name]: value
    });
  };

  const handleChangeTimezone = (event) => {
    const value = event.value;

    setCustomer({
      ...customer,
      tz: value
    });
  };

  const formTitle = id ? 'Edit Customer' : 'New Customer';

  return (
    <Grid.Row>
      <Grid.Col width={12}>
        <Card>
          <Card.Header>
            <Card.Title>{formTitle}</Card.Title>
          </Card.Header>
          <Card.Body>
            <Dimmer active={ isFormLoading } loader>
              <Form onSubmit={handleSubmit} noValidate>
                { id ? (
                  <Form.Group>
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Input
                      name="phoneNumber"
                      type="hidden"
                      value={customer.phoneNumber}
                    />
                    <Form.StaticText>{conformedPhoneNumber(customer.phoneNumber)}</Form.StaticText>
                  </Form.Group>
                  ) : ('')
                }
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Input
                    name="fullName"
                    type="text"
                    placeholder="Enter the customer name"
                    required
                    invalid={ isValid("fullName") }
                    feedback={ getFeedback("fullName") }
                    onChange={handleChange}
                    value={customer.fullName}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Input
                    name="email"
                    type="email"
                    placeholder="Enter the customer email"
                    required
                    invalid={ isValid("email") }
                    feedback={ getFeedback("email") }
                    onChange={handleChange}
                    value={customer.email}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    <Icon name="phone" /> Enabled
                  </Form.Label>
                  <Form.Switch
                    className="inverted-switch"
                    name="disabled"
                    type="checkbox"
                    label=""
                    onChange={handleChange}
                    value={customer.disabled}
                    checked={customer.disabled ? 'checked' : ''}
                  />
                </Form.Group>
                <Grid.Row>
                  <Grid.Col width={12} sm={4}>
                    <Form.Group>
                      <Form.Label>
                        <Icon name="voicemail" /> Call Recording
                      </Form.Label>
                      <Form.Switch
                        name="callRecording"
                        type="checkbox"
                        label=""
                        onChange={handleChange}
                        value={customer.callRecording}
                        checked={customer.callRecording ? 'checked' : ''}
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col width={12} sm={4}>
                    <Form.Group>
                      <Form.Label>
                        <Icon name="list" /> Play Menu
                      </Form.Label>
                      <Form.Switch
                        className="inverted-switch"
                        name="supportMenuDisabled"
                        type="checkbox"
                        label=""
                        onChange={handleChange}
                        value={customer.supportMenuDisabled}
                        checked={customer.supportMenuDisabled ? 'checked' : ''}
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col width={12} sm={4}>
                    <Form.Group>
                      <Form.Label>
                        <Icon name="key" /> Access Code
                      </Form.Label>
                      <Form.Switch
                        className="inverted-switch"
                        name="accessCodeDisabled"
                        type="checkbox"
                        label=""
                        onChange={handleChange}
                        value={customer.accessCodeDisabled}
                        checked={customer.accessCodeDisabled ? 'checked' : ''}
                      />
                      {!customer.accessCodeDisabled && (
                        <>
                          <span className="access-code-box">
                            {customer.accessCode}
                          </span>
                        </>
                      )}
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>
                <Form.Group>
                  <Form.Label>Timezone</Form.Label>
                  <Select
                    className="cs-select-container"
                    classNamePrefix="react-select"
                    name="tz"
                    value={Timezones.filter(timezone => timezone.value === customer.tz)}
                    onChange={handleChangeTimezone}
                    isSearchable={true}
                    backspaceRemovesValue={false}
                    options={Timezones}
                    getOptionValue={(Timezones)=>Timezones.value}
                    getOptionLabel={(Timezones)=>Timezones.name}
                    placeholder="Select..."
                    menuPlacement="top"
                  />
                </Form.Group>
                <Button color="purple" type="submit">Save</Button>
              </Form>
            </Dimmer>
          </Card.Body>
        </Card>
      </Grid.Col>
    </Grid.Row>
  )
}

const getCustomerId = (customer) => (customer.phoneNumber && customer.phoneNumber)

const List = () => {
  const [allRows, setAllRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const defaultRemoveData = {
    alert: false,
    id: '',
    name: ''
  }
  const [removeAlert, setRemoveAlert] = useState(defaultRemoveData);

  useEffect(() => {
    setIsLoading(true);
    getCustomers().then(data => {
      setAllRows(data);
      setIsLoading(false);
    })
  }, []);

  const handleClickRemoveAlert = (id, name) => {
    setRemoveAlert({alert: true, id: id, name: name});
  }

  const handleDelete = (id) => {
    setIsLoading(true);
    deleteCustomer(id)
      .then(res => {
        _.remove(allRows, {
          phoneNumber: id
        });
        setAllRows(allRows);
        showNotification("Success", "Customer has been deleted successfully", "success");
        setRemoveAlert(defaultRemoveData);
        setIsLoading(false);
      })
      .catch((error) => {
        setRemoveAlert(defaultRemoveData);
        setIsLoading(false);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          showNotification("Error", "Customer has not been deleted. Please try again later.", "danger");
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.error(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          showNotification("Error", error.message, "danger");
        }
      });
  }

  const customerItems = (collection) => (
    collection.map(cu => ({
      key: cu.phoneNumber,
      item: [
        { "data-label": "Phone Number", content: conformedPhoneNumber(cu.phoneNumber) },
        { "data-label": "Name", content: cu.fullName },
        { "data-label": "Email", content: cu.email },
        { "data-label": "Timezone", content: formattedTimezone(cu.tz) },
        { "data-label": "Enabled", className: "text-lg-center icon-as-data", content: cu.disabled ? <Icon name="phone-off" className="text-danger" /> : <Icon name="phone" className="text-success" /> },
        { "data-label": "Recording", className: "text-lg-center icon-as-data", content: cu.callRecording ? <Icon name="voicemail" className="text-success" /> : <Icon name="voicemail" className="text-danger" /> },
        { "data-label": "Menu", className: "text-lg-center icon-as-data", content: cu.supportMenuDisabled ? <Icon name="list" className="text-danger" /> : <Icon name="list" className="text-success" /> },
        { "data-label": "Code", className: "text-lg-center icon-as-data", content: cu.accessCodeDisabled ? <Icon name="key" className="text-danger" /> : <Icon name="key" className="text-success" /> },
        { "data-label": "", className: "text-lg-right", content:
          (
            <>
              <NavLink className="btn btn-sm btn-secondary" to={"/customers/" + getCustomerId(cu) + "/edit"}><Icon name="edit" /></NavLink>
              <Button color="danger" size="sm" className="ml-2" onClick={ () => handleClickRemoveAlert(getCustomerId(cu), cu.fullName) }><Icon name="trash-2" /></Button>
            </>
          ),
        },
      ]
    }))
  );

  const csvHeaders = [
    { label: "Phone Number", key: "phoneNumber" },
    { label: "Name", key: "fullName" },
    { label: "Email", key: "email" },
    { label: "Timezone", key: "tz" }
  ];

  return (
    <>
      { removeAlert.alert &&
        <ReactMiniAlertConfirm
          text={"Deleting " + removeAlert.name + ".\nAre you sure?"}
          ico=""
          callback={ () => handleDelete(removeAlert.id) }
          closeCallback={ () => setTimeout(() => { setRemoveAlert(defaultRemoveData); }, 350) }
        />
      }
      <Grid.Row>
        <Grid.Col width={12}>
          <Card>
            <Card.Header>
              <Card.Title>Customers List</Card.Title>
              <Card.Options>
                <CSVLink
                  data={allRows}
                  headers={csvHeaders}
                  filename={"call-support-customers.csv"}
                  className="btn btn-sm btn-secondary mr-2"
                  target="_blank"
                >
                  Export to CSV
                </CSVLink>
                <NavLink className="btn btn-sm btn-purple" to="/customers/new">
                  <Icon name="plus" />
                </NavLink>
              </Card.Options>
            </Card.Header>
            <Dimmer active={ isLoading } loader>
              <Table
                className="card-table table-vcenter responsive-table"
                headerItems={[
                  { content: "Phone Number" },
                  { content: "Name" },
                  { content: "Email" },
                  { content: "Timezone" },
                  { content: "Enabled", className: "text-center" },
                  { content: "Recording", className: "text-center" },
                  { content: "Menu", className: "text-center" },
                  { content: "Code", className: "text-center" },
                  { content: "" },
                ]}
                bodyItems={customerItems(allRows)}
              />
            </Dimmer>
          </Card>
        </Grid.Col>
      </Grid.Row>
    </>
  )
}

export default List;
