import { API } from 'aws-amplify'

const api = {
  get: (endpoint, options = {}) => API.get('callSuptApi', endpoint, options),
  post: (endpoint, options = {}) => API.post('callSuptApi', endpoint, options),
  put: (endpoint, options = {}) => API.put('callSuptApi', endpoint, options),
  delete: (endpoint, options = {}) => API.del('callSuptApi', endpoint, options),
}

export default api;
